@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
    padding: 0;
    margin: 0;
}

body {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    @apply bg-gray-200 dark:bg-gray-800 text-black dark:text-white;
}

.menu-item {
    @apply border-b border-gray-300 dark:border-gray-700 p-4;
    display: flex;
    align-items: center;
}

.menu-item:last-child {
    @apply border-b-0; /* Remove border-bottom for the last item */
}

.menu-item-img {
    @apply w-24 h-24 object-cover mr-4 rounded shadow-lg;
    flex-shrink: 0; /* Prevent image from shrinking */
}

.menu-item-details {
    @apply flex flex-col justify-center;
}

.menu-item-title {
    @apply font-semibold mb-2;
}

.menu-section {
    @apply mb-12; /* Increase spacing between sections */
}

.menu-section h2 {
    @apply mb-4; /* Spacing after section title */
}

.menu-section h3 {
    @apply mt-4 mb-2; /* Spacing for subsection titles */
}

.menu-item-img {
    @apply w-24 h-24 object-cover mr-4 rounded shadow-lg;
    flex-shrink: 0; /* Prevent image from shrinking */
}

.menu-item-title {
    @apply font-semibold mb-2 text-lg; /* Increase font size */
    flex: 1 1 auto; /* Allow it to expand */
}

.menu-item-description {
    @apply text-sm mt-1; /* Add a margin to descriptions and reduce font size */
    flex: 1 1 100%; /* Allow it to span full width */
}

.menu-subsection {
    @apply border-t border-gray-300 dark:border-gray-700 mt-4 pt-4; /* Add top border and spacing for subsections */
}